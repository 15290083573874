import Swiper, { Navigation } from 'swiper';

export default {

  init(productsSlider) {

    window.isLoaded(() => {

      let sliderContainer = productsSlider.closest('.container');

      new Swiper(productsSlider, {

        modules: [Navigation],
        slideClass: 'product',
        grabCursor: true,
        speed: 400,
        spaceBetween: 20,
        slidesPerView: 'auto',
        navigation: {
          prevEl: sliderContainer.querySelector('.button-prev'),
          nextEl: sliderContainer.querySelector('.button-next'),
        },
        breakpoints: {
          390: {
            spaceBetween: 20,
            slidesPerView: 2.5,
          },
          1440: {
            spaceBetween: 64,
            slidesPerView: 2,
          },
        },

      });

    });

  },
}

import productsSlider from '../modules/sliders/products-slider';
import contentColumns from '../modules/sliders/content-columns';
import titleImageSlider from '../modules/sliders/title-image-slider';
import accordion from '../modules/accordion';

export default {
  init() {

    // Init products sliders
    document.querySelectorAll('.products-slider').forEach(slider => {
      productsSlider.init(slider)
    });

    // Init projects slider
    document.querySelectorAll('.projects-slider-section').forEach(projectsSection => {
      titleImageSlider.init(
        projectsSection.querySelector('.titles-container'),
        projectsSection.querySelector('.images-container')
      )
    });

    // Init faq accordion
    accordion.init(document.querySelector('.accordion'));

    // Init content columns sliders
    document.querySelectorAll('.content-columns-section .gallery-container').forEach(galleryContainer => {
      contentColumns.init(galleryContainer);
    });

  },
}

import processTabs from '../modules/sliders/process-tabs';
import titleImageSlider from '../modules/sliders/title-image-slider';

export default {
  init() {

    // Init process tabs sections
    document.querySelectorAll('.process-tabs-container').forEach(container => processTabs.init(container));

    // Init projects slider
    document.querySelectorAll('.projects-slider-section').forEach(projectsSection => {
      titleImageSlider.init(
        projectsSection.querySelector('.titles-container'),
        projectsSection.querySelector('.images-container')
      )
    });
  },
}

import helpers from '../util/helpers';
const cookie_name = 'stonecycling_cookie_preference';
const cookie_accepted_event = 'stonecycling_cookies_accepted';
let cookieContainer;

export default {
  init() {

    window.isLoaded(() => {

      cookieContainer = document.querySelector('.cookie-container');

      checkCookie();

      cookieContainer.querySelector('.accept-cookies').addEventListener('click', acceptCookies);
      cookieContainer.querySelector('.close').addEventListener('click', closeCookies);

    });

  },

};

const acceptCookies = () => {

  setCookie(cookie_name, 1, 90);
  cookieContainer.querySelector('.accept-cookies').removeEventListener('click', acceptCookies);

}

const closeCookies = () => {

  setCookie(cookie_name, 0, 90);
  cookieContainer.querySelector('.cookie-container .close').removeEventListener('click', closeCookies);

}


/*
 * Sets the cookie and hides the notice
 */
const setCookie = (cname, cvalue, exdays) => {

  var d = new Date();

  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

  var expires = 'expires='+d.toUTCString();

  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'event': cookie_accepted_event});

  cookieContainer.classList.add('close');

  setTimeout(function() {

    cookieContainer.style.display = 'none';

  }, 600);

}


/*
 * Checks if there is a cookie on page load
 */

const checkCookie = () => {

  var cookie_preference = helpers.getCookie(cookie_name);

  if (cookie_preference != '') {

    cookieContainer.style.display = 'none';

  } else {

    cookieContainer.style.display = 'block';

  }
}

import tabs from '../modules/tabs';
import customMadeSlider from '../modules/sliders/custom-made-slider';
import titleImageSlider from '../modules/sliders/title-image-slider';

export default {
  init() {

    if(document.querySelector('.tab-lists')) {


      tabs.init(document.querySelector('.tab-lists > .tabs'), document.querySelector('.tab-lists > .tab-content'));

    }

    document.querySelectorAll('.slider-section .slider-container').forEach(sliderContainer => {

      customMadeSlider.init(sliderContainer);

    })

    // Init projects slider
    document.querySelectorAll('.projects-slider-section').forEach(projectsSection => {
      titleImageSlider.init(
        projectsSection.querySelector('.titles-container'),
        projectsSection.querySelector('.images-container')
      )
    });

  },
}

import tabs from '../modules/tabs';

export default {
  init() {

    if(document.querySelector('.stakeholders')) {

      tabs.init(document.querySelector('.stakeholders > .tabs'), document.querySelector('.stakeholders > .tab-content'));

    }

  },
}

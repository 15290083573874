import Choices from 'choices.js';
export default {
  init() {

    document.querySelectorAll('.wpcf7-form').forEach(form => {

      form.querySelectorAll('select[multiple]:not(.hidden)').forEach(multiSelect => {
        multiSelect.Choices = new Choices(multiSelect, {
          removeItemButton: true,
          removeItemButtonAlignLeft: true,
        });
      });
    });

  },
};

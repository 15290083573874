import Swiper, { Navigation } from 'swiper';

export default {

  init(galleryContainer) {

    window.isLoaded(() => {

      new Swiper(galleryContainer, {

        modules: [Navigation],
        grabCursor: true,
        spaceBetween: 24,
        slidesPerView: 1,
        speed: 400,
        navigation: {
          prevEl: galleryContainer.querySelector('.button-prev'),
          nextEl: galleryContainer.querySelector('.button-next'),
        },

      });

    });

  },

}

// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_foundation.js'

import helpers from 'scripts/util/helpers';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import templateProducts from './routes/template-products';
import templateProjects from './routes/template-projects';
import singleProject from './routes/single-project';
import singleProduct from './routes/single-product';
import templateHowitsmade from './routes/template-howitsmade';
import templateFaq from './routes/template-faq';
import templatePress from './routes/template-press';
import templateContact from './routes/template-contact';
import templateCustomMade from './routes/template-custom-made';
import templateShoppingCart from './routes/template-shopping-cart';
import templateCheckout from './routes/template-checkout';
import templateIntake from './routes/template-intake';
import templateContactExpert from './routes/template-contact-expert';
import templateGeneral from './routes/template-general';
import templateDownloadCenter from './routes/template-download-center';
import templateProductGroup from './routes/template-product-group';
import templateQuote from './routes/template-quote';
/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Templates
  home,
  templateProducts,
  templateProductGroup,
  templateProjects,
  singleProject,
  singleProduct,
  templateHowitsmade,
  templateFaq,
  templatePress,
  templateContact,
  templateCustomMade,
  templateShoppingCart,
  templateCheckout,
  templateIntake,
  templateContactExpert,
  templateGeneral,
  templateDownloadCenter,
  templateQuote,
});

// Global accessible variables
window.main = main_object; // eslint-disable-line
window.isLoaded = helpers.windowIsLoaded;
window.isReady = helpers.windowIsReady;

// Load Events
window.isReady(() => routes.loadEvents());
// jQuery(document).ready(() => routes.loadEvents());

/**
 * Add fn as callback to listener on window load event.
 * If the load event has already happened execute the callback right away and remove the load event listener
 * @param  {Function} fn
 */
const windowIsLoaded = (fn) => {

  // Add load event listener that calls fn()
  window.addEventListener('load', fn);

  // If the load event is missed, and the document readyState is complete, call fn()
  if (document.readyState === 'complete' ) {
    // window.removeEventListener('load', fn);
    fn();
  }

};

/**
 * Add fn as callback to listener on window DOMContentLoaded event.
 * If the DOMContentLoaded event has already happened execute the callback right away
 * @param  {Function} fn
 */
const windowIsReady = (fn) => {

  // Add DOMContentLoaded event listener that calls fn()
  window.addEventListener('DOMContentLoaded', fn);

  // If the DOMContentLoaded event has already happened, and the document readyState is complete, call fn()
  if (document.readyState === 'interactive' || document.readyState === 'complete' ) {
    // window.removeEventListener('DOMContentLoaded', fn);
    fn();
  }
};

/**
 * Set a css variable for the panel height
 * @param  {HTMLElement} element
 */
const setElementHeight = (element, height) => {

  height = height || element.scrollHeight;
  element.setAttribute('style', `--element-height:${height}px`);

};

/**
 * Set height css variable on element and update on resize
 * @param  {HTMLElement} element
 */
const trackElementHeight = (element) => {

  window.isLoaded(() => {

    // Calculate the height the element occupies
    setElementHeight(element);
    window.addEventListener('resize', setElementHeight.bind(null, element, null));

  });

};

/**
 * Helper to find out if the device is a touch device
 * @return {Boolean}
 */
const isTouchDevice = () => {

  return (('ontouchstart' in window)
    || (navigator.MaxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));

};

/**
 * Get a random integer between 2000 and 6000
 * @return {int}
 */
const getRandomInteger = (min=0, max=100) => {

  return Math.floor(Math.random() * (max - min + 1)) + min;

};

/**
   * Promise constructor for lazyloading images
   * @param  {function}  resolve
   * @return {Promise}
   */
const isLazyLoaded = (image) => {

  return new Promise((resolve) => {

    if(!image || image.classList.contains('lazyloaded')) {

      resolve();

    } else {

        image.addEventListener('lazyloaded', resolve);

    }
  });
}

/**
 * Get a cookie by name
 * @param {string} cname
 * @return {string}
 */
const getCookie = (cname) => {

  var name = cname + '=';
  var ca = document.cookie.split(';');

  for(var i = 0; i < ca.length; i++) {

    var c = ca[i];

    while (c.charAt(0) == ' ') {

      c = c.substring(1);

    }

    if (c.indexOf(name) == 0) {

      return c.substring(name.length, c.length);

    }

  }

  return '';

}

export default {
  windowIsLoaded: windowIsLoaded,
  windowIsReady: windowIsReady,
  setElementHeight: setElementHeight,
  trackElementHeight: trackElementHeight,
  isTouchDevice: isTouchDevice,
  getRandomInteger: getRandomInteger,
  isLazyLoaded: isLazyLoaded,
  getCookie: getCookie,
}

class Breakpoints {

  constructor(queries) {

    /** Breakpoints object.
    * Default must be the first property and shouldn't have a min-width
    * The last property shouldn't have a max-width
    * */
    this.breakpoints = {
      default: 'screen and (max-width: 390px)',
      small: 'screen and (min-width: 391px)',
      medium: 'screen and (min-width: 640px)',
      mediumLarge: 'screen and (min-width: 768px)',
      large: 'screen and (min-width: 1024px)',
      xlarge: 'screen and (min-width: 1200px)',
      xxlarge: 'screen and (min-width: 1440px)',
      xxxlarge: 'screen and (min-width: 1640px)',
      nav: 'screen and (min-width: 1024px)',
    };

    this.currentSize = 'default';

    // The queries object must have a default property
    if(!Object.prototype.hasOwnProperty.call(queries, 'default')) {

      throw 'Queries object given to watch method must have a property called \'default\'';

    }

    this.watch(queries);

  }

  watch(queries) {

    // Get the current size and run the callback once
    this.currentSize = this.getSize(queries);

    this.runCallback(queries);

    // On resize, check if the size has changed, if so, run the matching callback
    window.addEventListener('resize', () => {

      let newSize = this.getSize(queries);

      if(newSize != this.currentSize) {

        this.currentSize = newSize;
        this.runCallback(queries);

      }

    });

  }

  /**
 * Get the current size, if no breakpoint matches, the current size will be returned
 * @param  {object} queries
 * @return {string}
 */
  getSize(queries) {

    let result = 'default';

    // Loop through all given queries
    for(const size in queries) {

      // If a query matches, make that size the result
      if(window.matchMedia(this.breakpoints[size]).matches) {

        result = size;

      }
    }

    return result;
  }

  /**
   * Run the callback from the queries object, throw error if the callback is not a function
   * @param  {object} queries
   */
  runCallback(queries) {

    // Run the callback of the activeSize or throw an error of the value is not a function
    if(typeof queries[this.currentSize] === 'function') {

      queries[this.currentSize].call();

    } else {

      throw `The property ${this.currentSize} must be of type function`;

    }
  }
}

export default Breakpoints;

export default {
  init() {

    // Window ready event
    window.isReady(() => {

      let pressForm = document.querySelector( '.press-form .wpcf7' );

      if(pressForm) {

        pressForm.addEventListener( 'wpcf7submit', (event) => {


          if(event.detail.apiResponse.status == 'mail_sent') {

            pressForm.style.display = 'none';

            let messageElement = pressForm.closest('.form').querySelector('.message');

            messageElement.innerHTML = event.detail.apiResponse.message;
            messageElement.classList.add('show');

          }

        });

      }

    });

  },
}

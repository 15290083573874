import imagesSlider from '../modules/sliders/images-slider';
import customMadeSlider from '../modules/sliders/custom-made-slider';
import titleImageSlider from '../modules/sliders/title-image-slider';
import accordion from '../modules/accordion';

export default {
  init() {

    document.querySelectorAll('.tabs-wrapper .tab').forEach(tab => tab.addEventListener('click', scrollToSection.bind(null, tab)));

    // Init images slider
    if(document.querySelector('.images-slider')) {
      imagesSlider.init(document.querySelector('.images-slider'));
    }

    // Init bespoke slider
    if(document.querySelector('.custom-made .slider-container')) {
      customMadeSlider.init(document.querySelector('.custom-made .slider-container'));
    }

    // Init projects slider
    document.querySelectorAll('.projects-slider-section').forEach(projectsSection => {
      titleImageSlider.init(
        projectsSection.querySelector('.titles-container'),
        projectsSection.querySelector('.images-container')
      )
    });

    // Init faq accordion
    accordion.init(document.querySelector('.accordion'));
  },
}

/**
 * Smooth scroll to section
 * @param {HTMLElement} tab
 * @param {Event} e
 */
const scrollToSection = (tab, e) => {

  e.preventDefault();
  const sectionId = tab.getAttribute('href').replace('#', '');
  const section = document.getElementById(sectionId);
  if(section) {

    section.scrollIntoView({ behavior: 'smooth' });

  }

}

/* eslint-disable */
import Swiper, { Navigation } from 'swiper';
import accordion from '../modules/accordion';

let container, navigationContainer, stepsSlider, faq;
let heightUpdateSpeed = 300;
let isSubmitting = false;

export default {
  init() {

    container = document.querySelector('.steps-container');
    navigationContainer = container.closest('.container').querySelector('.navigation');
    faq = document.querySelector('.accordion');

    window.isLoaded(() => {

      // Init slider
      stepsSlider = new Swiper(container, {

        modules: [Navigation],
        // autoHeight: true,
        allowTouchMove: false,
        navigation: {
          prevEl: navigationContainer.querySelector('.prev'),
          nextEl: navigationContainer.querySelector('.next'),
        },
        on: {
          slideChange: () => {
            updateNavigation();
            updateWrapperHeight();
          },
        },

      });

      // Run height update
      updateWrapperHeight();

      // Run navigation update
      updateNavigation();

      // Listen to click on navigation buttons
      navigationContainer.querySelector('.submit').addEventListener('click', submitForm);

      // Listen to submit on forms (by hitting enter)
      container.querySelectorAll('.wpcf7 input').forEach(input => input.addEventListener('keypress', handleInputKeypress));

      // Toggle form tabindex
      handleFormTabIndex();

      // Listen to input validation to update height
      // container.querySelectorAll('.wpcf7 input').forEach(input => {

      //   input.addEventListener('change', (e) => {

      //     updateWrapperHeight();

      //   });

      // });

      container.querySelector('.wpcf7').addEventListener('click', (e) => {

        updateWrapperHeight();

      });


    });

    // Window ready event
    window.isReady(() => {

      // Init faq accordion
      accordion.init(faq);

      faq.querySelectorAll('.panel-toggle').forEach(button => button.addEventListener('click', updateWrapperHeight));


    });

  },

}

/**
 * Update navigation elements
 * - Activate and deactivate previous and next buttons
 */
const updateNavigation = () => {

  let navigationClass = stepsSlider.slides[stepsSlider.activeIndex].dataset.usesNavigation;

  navigationContainer.querySelectorAll('.slide-navigation').forEach(nav => nav.classList.remove('active'));
  navigationContainer.querySelector('.'+navigationClass).classList.add('active');

}


const submitForm = (e) => {

  // Find the form container
  let formContainer = stepsSlider.slides[stepsSlider.realIndex].querySelector('.wpcf7');

  // Continue if there is no form on this slide
  if(!formContainer) {
    return;
  }

  // Start listening for the form to submit and continue if the validation passes
  formContainer.addEventListener('wpcf7submit', (e) => {

    updateWrapperHeight();

    isSubmitting = false;

  }, { once: true });


  // Submit the form
  if(!isSubmitting) {

    isSubmitting = true;
    formContainer.querySelector('form [type=submit]').click();

  }

  updateWrapperHeight();

  // Prevent form field reset
  formContainer.querySelector('form').addEventListener('reset', (e) => e.preventDefault(), { once: true });

}

const updateWrapperHeight = (e) => {

  // Get the height of the active slide
  let slideHeight = stepsSlider.slides[stepsSlider.activeIndex].offsetHeight;
  let wrapperHeight = slideHeight + 'px';

  if(e !== undefined) {
    // Triggered by a accordion click
    const button = e.target.closest('.panel-toggle');
    const panel = button.closest('.accordion').querySelector('#'+button.getAttribute('aria-controls'));
    const direction = panel.getAttribute('aria-hidden') === 'true' ? 1 : -1;

    let panelHeight = getComputedStyle(panel).getPropertyValue('--panel-height');
    wrapperHeight = slideHeight + (direction * parseInt(panelHeight)) + 'px'

  }

  setTimeout(() => {
    let slideHeight = stepsSlider.slides[stepsSlider.activeIndex].offsetHeight;
    let wrapperHeight = slideHeight + 'px';
    stepsSlider.wrapperEl.style.height = wrapperHeight;
  }, 100);


}

const handleFormTabIndex = () => {

  let tabbables = 'input, textarea, select';

  container.querySelectorAll('.swiper-slide').forEach(slide => {

    let tabbable = slide.classList.contains('swiper-slide-active') ? '0' : '-1';

    slide.querySelectorAll(tabbables).forEach(el => {

      el.setAttribute('tabindex', tabbable);

    });
  });
};

/**
 * Check if the key pressed on the input is enter, if so, prevent default submission and trigger click on next navigation button instead
 * @param  {Event} e
 */
const handleInputKeypress = (e) => {

  let key = e.charCode || e.keyCode || 0;
  if (key == 13) {

    e.preventDefault();
    navigationContainer.querySelector('.next').click();

  }
}

import filters from '../modules/filters';
import accordion from '../modules/accordion';

export default {
  init() {

    if(document.querySelector('.filters')) {

      filters.init(
        document.querySelector('.results-container'),
        document.querySelector('.filters'),
        document.querySelector('.active-filters')
      );

    }

    // Init faq accordion
    accordion.init(document.querySelector('.accordion'));

  },
}

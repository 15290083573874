import Swiper, { Pagination, EffectFade } from 'swiper';

export default {

  init(container) {

    window.isLoaded(() => {

      new Swiper(container, {

        modules: [Pagination, EffectFade],
        slideClass: 'slide',
        speed: 400,
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.tabs',
          type: 'custom',
          bulletClass: 'tab',
          clickable: true,
          renderCustom: (swiper, current, total) => {

            let tabs = '';
            for(let i = 1; i<=total; i++) {

              tabs += `<button role="tab" aria-selected="${current == i}" class="tab">${swiper.slides[i-1].dataset.label}</button>`

            }

            return tabs;
          },
        },

      });

    });

  },
}

const init = (accordion) => {

  window.isLoaded(() => {

    if(accordion) {

      accordion.querySelectorAll('.panel-toggle').forEach((toggleButton) => {

        // Find the panel that the button controls
        const panel = accordion.querySelector('#'+toggleButton.getAttribute('aria-controls'));

        // Listen to clicks on the button
        toggleButton.addEventListener('click', handlePanelToggle.bind(null, toggleButton, panel, null));

        // Set panel height on load and resize
        setPanelHeight(panel);
        window.addEventListener('resize', setPanelHeight.bind(null, panel));

      });

    }

  });

}

/**
 * Handle click on the toggle button
 * @param  {HTMLElement} toggleButton
 * @param  {HTMLElement} panel
 * * @param  {bool|null} shouldExpand
 */
const handlePanelToggle = (toggleButton, panel, shouldExpand = null) => {

  if(shouldExpand === null) {
    shouldExpand = toggleButton.getAttribute('aria-expanded') == 'true' ? false : true;
  }

  toggleButton.setAttribute('aria-expanded', shouldExpand);
  panel.setAttribute('aria-hidden', !shouldExpand);

}

/**
 * Set a css variable for the panel height
 * @param  {HTMLElement} panel
 */
const setPanelHeight = (panel) => {

  let panelHeight = panel.querySelector('.panel-content').scrollHeight;

  panel.setAttribute('style', `--panel-height:${panelHeight}px`);

}

export default {
  init: init,
  setPanelHeight: setPanelHeight,
  handlePanelToggle: handlePanelToggle,
}

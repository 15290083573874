import Cookies from 'js-cookie';

class Cart {

  constructor(cookieKey) {

    this.cookieKey = cookieKey;
    this.cartIcon = document.querySelector('header .cart-button');
    this.maxProducts = 6;

    if(this.get() === undefined) {

      this.empty();

    }
  }

  get() {

    return Cookies.get(this.cookieKey);

  }

  getAmount() {

    return JSON.parse(this.get()).length;

  }

  getProductData(dataElement) {

    let data = {};
    data.itemId = dataElement.dataset.itemId;
    data.itemName = dataElement.dataset.itemName;
    data.itemSlug = dataElement.dataset.itemSlug;
    data.itemBrand = dataElement.dataset.itemBrand;
    data.itemVariant = dataElement.dataset.itemVariant;

    return data;
  }

  add(productDataElement) {

    let data = this.getProductData(productDataElement);
    let value = data.itemId;
    let legacyValue = this.createLegacyValue(data);
    let cartItems = JSON.parse(this.get());

    // Remove legacy value from cartItems if it exists
    if(cartItems.includes(legacyValue)){
      let itemIndex = cartItems.findIndex((item) => item == legacyValue);
      cartItems.splice(itemIndex, 1);
    }

    if(!cartItems.includes(value)) {

      cartItems.push(value);

      this.updateCart(cartItems);

      // Send datalayer event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            this.createProductItem(data),
          ],
        },
      });

    }
  }

  remove(productDataElement) {

    let data = this.getProductData(productDataElement);
    let value = data.itemId;
    let legacyValue = this.createLegacyValue(data);
    let cartItems = JSON.parse(this.get());
    let itemIndex;

    if(cartItems.includes(legacyValue)){
      itemIndex = cartItems.findIndex((item) => item == legacyValue);
    } else {
      itemIndex = cartItems.findIndex((item) => item == value);
    }

    if(itemIndex !== -1) {

      cartItems.splice(itemIndex, 1);

      this.updateCart(cartItems);

      // Send datalayer event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          items: [
            this.createProductItem(data),
          ],
        },
      });

    }

  }

  updateCart(cartItems) {

    // Update cookie
    Cookies.set(this.cookieKey, JSON.stringify(cartItems));

    // Update cart icon
    this.updateCartIcon(cartItems);

  }

  updateCartIcon(cartItems) {

    let amountElement = this.cartIcon.querySelector('.amount');
    amountElement.innerHTML = cartItems.length;
    amountElement.classList.toggle('empty', cartItems.length == 0);

  }

  createProductItem(data) {

    return {
      item_id: data.itemId,
      item_name: data.itemName,
      item_brand: data.itemBrand,
      item_variant: data.itemVariant,
      item_category5: data.itemVariant,
    }
  }

  createLegacyValue(data) {

    return `${data.itemId}|${data.itemSlug}`;

  }

  empty() {

    Cookies.set(this.cookieKey, JSON.stringify([]));

  }
}

export default Cart;

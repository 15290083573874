/* eslint-disable no-unused-vars */
import camelCase from '../util/camelCase';
import helpers from '../util/helpers';
import Breakpoints from '../util/Breakpoints';

let filtersContainer, resultsContainer, activeContainer;
let filterKeys = [];

export default {

  init(results, filters, active) {

    window.isReady(() => {

      resultsContainer = results;
      filtersContainer = filters;
      activeContainer = active;
      filtersContainer.querySelectorAll('[data-filter]').forEach(el => { filterKeys.push(el.dataset.filter) });
      filtersContainer.querySelectorAll('.filter-item input').forEach(input => { input.addEventListener('change', handleFilterChange.bind(null, input)) });

      // Check if any filter is requested from the url
      setFilterFromUrl();

      // Track height and listen for click on filters section
      let toggleFiltersWrapper = document.querySelector('.toggle-filters-wrapper')
      let toggleFiltersPanel = toggleFiltersWrapper.querySelector('.toggle-panel');

      helpers.trackElementHeight(toggleFiltersPanel);
      toggleFiltersWrapper.querySelector('.toggle-label').addEventListener('click', () => toggleFiltersWrapper.classList.toggle('open'));

      // Filter items toggle on small screens
      let toggleFiltersLists = toggleFiltersWrapper.querySelectorAll('fieldset');
      toggleFiltersLists.forEach(fieldset => {

        let fieldsetFilterItems = fieldset.querySelector('.filter-items');
        helpers.trackElementHeight(fieldsetFilterItems);

        let toggleFilterFieldset = () => {

          helpers.setElementHeight(fieldsetFilterItems);
          fieldset.classList.toggle('open');
          // Combined toggle-panel height
          let plusminus = fieldset.classList.contains('open') ? 1 : -1;
          let height = toggleFiltersPanel.scrollHeight + plusminus * fieldsetFilterItems.scrollHeight;
          helpers.setElementHeight(toggleFiltersPanel, height);

        }

        new Breakpoints({
          default: () => fieldset.querySelector('legend').addEventListener('click', toggleFilterFieldset),
          large:   () => {
            fieldset.querySelector('legend').removeEventListener('click', toggleFilterFieldset);
            // Set the toggle-panel to the filters elements height
            helpers.setElementHeight(toggleFiltersPanel, toggleFiltersPanel.querySelector('.filters').scrollHeight);
          },
        });
      });
    });

  },
}

const setFilterInputs = (currentInput) => {

  const filterItems = currentInput.closest('.filter-items');
  if(filterItems.hasAttribute('data-singular') && currentInput.checked){

    filterItems.querySelectorAll('input').forEach(input => {
      if(input !== currentInput && input.checked) {
        input.click();
      }
    });
  }

}

/**
 * Handle change of filter input element
 */
const handleFilterChange = (input) => {

  // Set filter inputs
  setFilterInputs(input);

  // Get all filter formdata
  let formData = new FormData(filtersContainer);

  setActiveLabels(input);

  // Loop through all products
  resultsContainer.querySelectorAll('.card').forEach(card => {

    // Start with hideCard as false
    let hideCard = false;

    // Loop through all filterKeys
    filterKeys.forEach(key => {

      // Get the filters with this key that are checked
      let activeFilters = formData.getAll(key);

      // Check if there are checked filters and the card is not already supposed to hide
      if(activeFilters.length && !hideCard) {

        // Compare the card filters with the active filters and set hideCard to true if there are no intersecting values
        let cardFilters = card.dataset[camelCase(`filter-${key}`)].split(',');
        if(activeFilters.filter(f => cardFilters.includes(f)).length === 0) {

          hideCard = true;

        }
      }

    });

    // Toggle the hidden class according to the hideCard boolean
    card.classList.toggle('hidden', hideCard);

  });

  // Check if the no-cards-message should show
  let noCards = resultsContainer.querySelectorAll('.card:not(.hidden)').length === 0;

  document.querySelector('.no-cards-message').classList.toggle('show', noCards);

};

/**
 * Set url params as active filters
 */
const setFilterFromUrl = () => {

  var searchParams = new URL(window.location.href).searchParams;
  var filterKeys = Array.from(searchParams.keys());

  filterKeys.forEach(key => {
    searchParams.getAll(key).forEach(value => {

      let input = filtersContainer.querySelector(`input[name=${key}][value=${value}]`);
      if(input) {
        input.checked = true;
        input.dispatchEvent(new Event('change'));
      }

    });

  });

}

const handleLabelClick = (label) => {

  let input = filtersContainer.querySelector(`[data-filter=${label.dataset.name}] input[value=${label.dataset.value}]`);
  if(input) {
    input.checked = false;
    input.dispatchEvent(new Event('change'));
  }
}

const setActiveLabels = () => {

  filtersContainer.querySelectorAll('.filter-item input').forEach(input => {

    let currentLabel = activeContainer.querySelector(`span[data-name=${input.name}][data-value=${input.value}]`);

    if(input.checked && !currentLabel) {

      let label = document.createElement('span');
      label.dataset.name = input.name;
      label.dataset.value = input.value;
      label.textContent = input.nextElementSibling.textContent;
      label.addEventListener('click', handleLabelClick.bind(null, label));
      activeContainer.appendChild(label);

    }

    if(!input.checked && currentLabel) {

      currentLabel.remove();

    }

  });

};

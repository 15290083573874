import Swiper, { Navigation, FreeMode } from 'swiper';

export default {

  init(container) {

    window.isLoaded(() => {

      new Swiper(container, {

        modules: [Navigation, FreeMode],
        slideClass: 'slide',
        grabCursor: true,
        speed: 400,
        freeMode: true,
        spaceBetween: 18,
        navigation: {
          prevEl: '.button-prev',
          nextEl: '.button-next',
        },
        breakpoints: {
          1200: {
            spaceBetween: 0,
          },
        },

      });

    });

  },
}

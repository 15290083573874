import Swiper, { Navigation, EffectFade, Controller } from 'swiper';

export default {

  init(titlesContainer, imagesContainer) {

    let titlesSlider, imagesSlider;

    window.isLoaded(() => {

      imagesSlider = new Swiper(imagesContainer, {

        modules: [Controller],
        slideClass: 'image-slide',
        grabCursor: true,
        speed: 400,

      });

      titlesSlider = new Swiper(titlesContainer, {

        modules: [Navigation, Controller, EffectFade],
        slideClass: 'text-slide',
        grabCursor: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 400,
        navigation: {
          prevEl: '.button-prev',
          nextEl: '.button-next',
        },

      });

      imagesSlider.controller.control = titlesSlider;
      titlesSlider.controller.control = imagesSlider;

    });

  },
}

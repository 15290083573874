import 'lazysizes';
import smoothscroll from 'smoothscroll-polyfill';
import Breakpoints from '../util/Breakpoints';
import nav from '../modules/navigation';
import tabs from '../modules/tabs';
import backgroundTabs from '../modules/sliders/background-tabs';
import elementReveal from '../components/element-reveal';
import journeySlider from '../modules/sliders/journey-slider';
// import video from '../modules/video';
import newsletter from '../modules/newsletter';
import cookies from '../modules/cookies';
import form from '../modules/form';

export default {
  init() {

    smoothscroll.polyfill();

    nav.init();

    cookies.init();

    form.init();

    // Set and update a vh variable that compensates for address bar height changes
    setRealVhUnit();
    window.addEventListener('resize', () => {

      setRealVhUnit();

    });

    // Init background tabs sections
    document.querySelectorAll('.background-tabs-section').forEach(container => backgroundTabs.init(container));

    // Init text-graphic-tabs sections
    document.querySelectorAll('.text-graphic-tabs-section .tabs-section').forEach(section => {

      let tabContent = section.querySelector(':scope > .tab-content');
      tabs.init(section.querySelector(':scope > .tabs-wrapper > .tabs'), tabContent);

      // Specifications tabs
      new Breakpoints({
        default: () => tabContent.dataset.fixedHeight = 0,
        mediumLarge: () => tabContent.dataset.fixedHeight = 1,
      });

    });

    // Reveal elements
    elementReveal.init('[reveal-element]');

    // Journey slider
    journeySlider.init(document.querySelector('.journey-slider-container'));

    // Init video flex blocks
    // video.init();

    // Newsletter form
    newsletter.init();

  },
};

/**
 * Calculate and set a vh var that is actually 1% of the window innerheight
 */
const setRealVhUnit = () => {

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

}


import helpers from '../util/helpers';
// import Breakpoints from '../util/Breakpoints';
import Cart from '../modules/Cart';
// import tabs from '../modules/tabs';
import titleImageSlider from '../modules/sliders/title-image-slider';
// import ImpactCalculator from '../modules/ImpactCalculator';
import accordion from '../modules/accordion';

export default {
  init() {

    // Window ready event
    window.isReady(() => {

      // Read variation from url
      setVariationFromUrl();

      // Listen to click on variation switch button
      document.querySelectorAll('.pick-variation .variations button').forEach(button => button.addEventListener('click', switchVariation.bind(null, button)));

      // Listen for click on order button
      document.querySelectorAll('.add-to-cart').forEach(button => button.addEventListener('click', addToCart.bind(null, button)));

      // Track height and listen for click on rules section
      let toggleRulesWrapper = document.querySelector('.toggle-rules-wrapper')
      helpers.trackElementHeight(toggleRulesWrapper.querySelector('.toggle-panel'));
      toggleRulesWrapper.querySelector('.toggle-label').addEventListener('click', () => toggleRulesWrapper.classList.toggle('open'));

      // Init product slider
      titleImageSlider.init(
        document.querySelector('.product-slider-section .titles-container'),
        document.querySelector('.product-slider-section .images-container')
      )

      // Init specifications tabs
      if(document.querySelector('.specifications')) {

        document.querySelectorAll('.specifications').forEach(specifications => {

          // Init specs accordion
          accordion.init(specifications.querySelector('.tab-content'));

          // Init tab behaviour above accordion
          specifications.querySelectorAll(':scope > .tabs-wrapper > .tabs .tab').forEach(tab => {

            const ariaControls = tab.getAttribute('aria-controls');
            const toggle = specifications.querySelector('.tab-content [aria-controls='+ariaControls+']');
            const panel = document.getElementById(ariaControls);

            // Sync tab aria-expanded with accordion toggle aria-expanded
            new MutationObserver((mutationList) =>{
              Array.from(mutationList).forEach(mutation => {
                  if (mutation.type === 'attributes') {

                    tab.setAttribute('aria-expanded', mutation.target.getAttribute('aria-expanded'));

                  }
              });
            }).observe(toggle, { attributes: true });

            // Listen to click on tab, open accordion and scroll to it
            tab.addEventListener('click', (e) => {
              e.preventDefault();

              accordion.handlePanelToggle(toggle, panel, true);

              toggle.scrollIntoView({ behavior: 'smooth' });
            });
          });

          // let tabContent = specifications.querySelector(':scope > .tab-content');
          // tabs.init(specifications.querySelector(':scope > .tabs-wrapper > .tabs'), tabContent);

          // Specifications tabs
          // new Breakpoints({
          //   default: () => tabContent.dataset.fixedHeight = 0,
          //   mediumLarge: () => tabContent.dataset.fixedHeight = 1,
          // });

          // Sizes tabs
          // if(specifications.querySelector('.sizes .sizes')) {

          //   tabs.init(specifications.querySelector('.sizes .sizes .tabs'), specifications.querySelector('.sizes .sizes .tab-content'));

          // }
        });
      }

      // Init projects slider
      document.querySelectorAll('.projects-slider-section').forEach(projectsSection => {
        titleImageSlider.init(
          projectsSection.querySelector('.titles-container'),
          projectsSection.querySelector('.images-container')
        )
      });

      // Init impact calculator
      // new ImpactCalculator(document.querySelector('.product-calculator-section'));

      // Init faq accordion
      accordion.init(document.querySelector('.accordion'));

    });

  },
}

/**
 * Toggle all data-variation elements based on the picked variation
 * @param  {DOMElement} button
 */
const switchVariation = (button) => {

  // Toggle active class on button
  button.parentElement.querySelectorAll('button').forEach(b => b.classList.toggle('active', b == button));

  // The clicked variation slug
  let variationSlug = button.dataset.setVariationSlug;
  let variationName = button.dataset.setVariationName;
  let variationId = button.dataset.setVariationId;

  // Toggle all elements with the variationSlug and hide all others
  document.querySelectorAll('[data-variation]').forEach(element => {

    element.classList.toggle('show', element.dataset.variation == variationSlug);

    // Set tab content height for specifications tab-content elements
    if(element.classList.contains('specifications')) {
      element.querySelectorAll('.panel').forEach(panel => accordion.setPanelHeight(panel));
    }

  });

  // // Update order button variation ids and slugs
  // document.querySelectorAll('.add-to-cart').forEach(orderButton => {

  //   orderButton.dataset.variationId = variationId;
  //   orderButton.dataset.variationSlug = variationSlug;

  // });

  // Update the product-data variation
  let dataElement = document.querySelector('.product-data');
  dataElement.dataset.itemId = `${dataElement.dataset.itemId.split('_')[0]}_${variationId}`;
  dataElement.dataset.itemSlug = `${dataElement.dataset.itemSlug.substring(0, dataElement.dataset.itemSlug.lastIndexOf('_'))}_${variationSlug}`;
  dataElement.dataset.itemVariant = variationName;

  // Update the page url
  updateUrl(variationSlug);

}

/**
 * Read variation from url and switch if that variation exists
 */
const setVariationFromUrl = () => {

  let searchParams = new URLSearchParams(window.location.search)
  let variationToLoad = searchParams.get('variation');
  let variationButton = document.querySelector(`[data-set-variation-slug=${variationToLoad}]`);

  if(variationButton) {

     switchVariation(variationButton);

  }
}

/**
 * Set the variation slug in the page url
 * @param  {[type]} variationSlug [description]
 * @return {[type]}             [description]
 */
const updateUrl = (variationSlug) => {

  if ('URLSearchParams' in window) {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set('variation', variationSlug);

    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    history.replaceState(null, '', newRelativePathQuery);
  }

}

/**
 * Add product to the cart
 * @param  {DOMElement} button
 */
const addToCart = (button) => {
  let cart = new Cart('sc-cart');
  let dataElement = document.querySelector('.product-data');
  cart.add(dataElement);

  let addedLabel = button.parentElement.querySelector('.sample-added-label')
  addedLabel.classList.remove('flash');
  void addedLabel.offsetWidth;
  addedLabel.classList.add('flash');
}

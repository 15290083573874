import helpers from '../util/helpers';
import tabs from '../modules/tabs';
import titleImageSlider from '../modules/sliders/title-image-slider';
// import productsSlider from '../modules/sliders/products-slider';
import collectionSlideshow from '../modules/sliders/collection-slideshow';
import Swiper, { Pagination, EffectFade } from 'swiper';
// import textGallerySlider from '../modules/sliders/text-gallery-slider';


const init = () => {

    initHero();

    // Init products sliders
    // document.querySelectorAll('.products-slider').forEach(slider => {
    //   productsSlider.init(slider)
    // });

    document.querySelectorAll('.collection-slideshow').forEach(slideshow => {
      collectionSlideshow.init(slideshow);
    });

    // Init custom made slider
    // textGallerySlider.init(document.querySelector('.home-custom-made'));

    // Init projects slider
    document.querySelectorAll('.projects-slider-section').forEach(projectsSection => {
      titleImageSlider.init(
        projectsSection.querySelector('.titles-container'),
        projectsSection.querySelector('.images-container')
      )
    });

    if(document.querySelector('.solution-tabs')) {

      tabs.init(
        document.querySelector('.solution-tabs > .tabs'),
        document.querySelector('.solution-tabs > .tab-content')
       );

    }

}

const initHero = () => {

  let heroSwiperContainer = document.querySelector('.home-hero-section .background-container');

  if(heroSwiperContainer.querySelectorAll('.swiper-slide').length > 1) {
    // Hero video slider
    new Swiper(heroSwiperContainer, {

      modules: [Pagination, EffectFade],
      effect: 'fade',
      allowTouchMove: false,
      loop: true,
      speed: 1500,
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
      },
      on: {
        init: (swiper) => {

          // Slide to next slide if video ends
          swiper.slides.forEach((slide) => {

            let video = slide.querySelector('video');
            if(video) {
              video.addEventListener('ended', () => {

                swiper.slideNext();
                video.pause();

              });
            }

          });

        },

        slideChange: (swiper) => {

          // Start the next video when a slide change happens (also fires on first slide)
          let video = swiper.slides[swiper.activeIndex].querySelector('video');
          if(video) {

            video.currentTime = 0;
            video.play();

          }

        },
      },
    });
  } else {

    let slide = heroSwiperContainer.querySelector('.swiper-slide');
    let video = slide.querySelector('video')

    slide.classList.add('swiper-slide-active');

    if(video && helpers.getCookie('popupSeen') == '1') {

      video.currentTime = 0;
      video.loop = true;
      video.play();

    }

  }
}

export default {
  init: init,
  initHero: initHero,
};

import Breakpoints from '../util/Breakpoints';
import helpers from '../util/helpers';

const navElement = document.querySelector('nav.nav-primary');

export default {

  init() {

    // Window ready event
    window.isReady(() => {

      // Initialize navigation
      initNavigation();

      // Calculate the height each submenu should take
      navElement.querySelectorAll('.sub-menu-wrapper').forEach(subMenuWrapper => {
        helpers.trackElementHeight(subMenuWrapper);
      });

    });

  },

}

/**
 * Initialize navigation behaviour
 */
const initNavigation = () => {

  // Add click listener to navigation toggle
  navElement.querySelector('.toggle-navigation').addEventListener('click', toggleNavigation);

  // Store a submenu toggleHandler on each menu item that has item for later reference
  navElement.querySelectorAll('.menu-item-has-children, .language-switch').forEach(menuItem => {

    // Store toggle handler on menuItem for later reference
    menuItem.toggleHandler = toggleSubmenu.bind(null, menuItem);

    // Calculate the offset from the left edge of the viewport to the menu item
    const offset = menuItem.getBoundingClientRect().left;

    // Get the submenu wrapper
    const submenuWrapper = menuItem.querySelector('.sub-menu-wrapper');

    // Set the custom property with the calculated offset
    if (submenuWrapper) {
      submenuWrapper.style.setProperty('--menu-item-offset', `${offset}px`);
    }
  });

  let languageSwitch = navElement.querySelector('.language-switch');
  if(languageSwitch) {
    languageSwitch.querySelector('.current').addEventListener('click', languageSwitch.toggleHandler);
  }

  // Add resize listener to update submenu offsets
  window.addEventListener('resize', () => {
    navElement.querySelectorAll('.menu-item-has-children').forEach(menuItem => {
      const offset = menuItem.getBoundingClientRect().left;
      const submenuWrapper = menuItem.querySelector('.sub-menu-wrapper');
      if (submenuWrapper) {
        submenuWrapper.style.setProperty('--menu-item-offset', `${offset}px`);
      }
    });
  });

  // Watch for resize breakpoints to update the navigation
  new Breakpoints({
    default: () => {
      updateNavigation('small');
    },
    nav: () => {
      updateNavigation('large');
    },
  });

};

/**
 * Update navigation behaviour based on screen size and touch capabilities
 * @param  {string} screenSize
 */
const updateNavigation = (screenSize) => {

  // Store screensize on the nav element
  navElement.screenSize = screenSize;

  // Loop through all menuItems with children
  navElement.querySelectorAll('.menu-item-has-children.depth-0').forEach(menuItem => {

    // Close the main navigation and submenu open state on large screens
    if(screenSize == 'large') {

      toggleNavigation(false);
      closeAllSubmenus();

    }

    // Remove hover listeners and add click listeners to all submenu toggles on small screens and touch devices
    if(screenSize == 'small' || helpers.isTouchDevice()) {

      menuItem.querySelector(':scope > a, :scope > span').removeEventListener('mouseenter', menuItem.toggleHandler);
      menuItem.removeEventListener('mouseleave', menuItem.toggleHandler);
      menuItem.querySelector(':scope > a, :scope > span').addEventListener('click', menuItem.toggleHandler);

    }

    // Remove click listeners and add hover listeners to all submenu toggles on non-touch large screens
    else {

      menuItem.querySelector(':scope > a, :scope > span').removeEventListener('click', menuItem.toggleHandler);
      menuItem.querySelector(':scope > a, :scope > span').addEventListener('mouseenter', menuItem.toggleHandler);
      menuItem.addEventListener('mouseleave', menuItem.toggleHandler);

    }

  });

}


/**
 * Toggle the submenu of a menu item
 * @param  {HTMLElement} menuItem
 * @param  {mixed} activate boolean or event
 */
const toggleSubmenu = (menuItem, activate) => {

  // Prevent click on parent menu item and toggle based on current state
  if(activate instanceof PointerEvent) {
    activate.preventDefault();
    activate = !menuItem.classList.contains('open');
  }

  // If activate is a mousevent, activate based on leaving or entering
  if(activate instanceof MouseEvent) {
    activate = activate.type == 'mouseleave' ? false : true;
  }

  // Close all open submenus
  if(navElement.screenSize == 'large') {

    closeAllSubmenus();

  }

  // Toggle the open class on the menu item and navElement
  console.log(menuItem)
  menuItem.classList.toggle('open', activate);
  navElement.closest('header').classList.toggle('nav-open', activate);

  // Copy the current sub-menu-wrapper height to the sub-menu-backdrop
  let subMenuWrapperStyle = menuItem.querySelector('.sub-menu-wrapper').getAttribute('style');
  navElement.querySelector('.sub-menu-backdrop').setAttribute('style', subMenuWrapperStyle);

  // Add click listener on document if the submenu is open
  if(activate) {

    // Stop the current event, to skip the handleNavigationBlur this time
    event.stopPropagation();
    document.addEventListener('click', handleNavigationBlur);

  }

  if(menuItem.classList.contains('language-switch')) {

    navElement.querySelector('.toggle-navigation').classList.toggle('hide', activate);

  }
}

/**
 * Loop through all opened menu items and close them.
 * Also remove the document event listener that handles blur clicks
 *
 */
const closeAllSubmenus = () => {

  // Close all open submenus
  navElement.querySelectorAll('.menu-item-has-children.open, .language-switch.open').forEach(menuItem => {

    menuItem.classList.toggle('open', false);

  });

  // Remove nav-open class from navElement
  navElement.closest('header').classList.toggle('nav-open', false);

  // Remove the event listener for the document click
  document.removeEventListener('click', handleNavigationBlur);

}

/**
 * Handler that calls closeAllSubmenus if the clicked target isn't (a child of) an open submenu
 * @param  {PointerEvent} event
 */
const handleNavigationBlur = (event) => {

  // If the event target is not a submenu itself
  // if(!event.target.closest('.sub-menu-wrapper')) {
  if(!event.target.closest('.menu-item')) {

    closeAllSubmenus();

  }
}

/**
 * Toggle the main navigation
 * @param  {mixed} activate boolean or event
 */
const toggleNavigation = (activate) => {

  // If activate isn't a boolean, use the nav-open class on the navElement
  activate = (typeof(activate) === 'boolean') ? activate : !navElement.classList.contains('nav-open');

  navElement.classList.toggle('nav-open', activate);
  document.querySelector('header.main').classList.toggle('nav-open', activate);
  document.querySelector('body').classList.toggle('no-scroll', activate);

  // Adjust labels
  let toggleElement = navElement.querySelector('.toggle-navigation');
  toggleElement.setAttribute('aria-label', activate ? toggleElement.dataset.labelClose : toggleElement.dataset.labelOpen);

}

import Swiper, { Navigation } from 'swiper';

export default {

  init(imagesSlider) {

    window.isLoaded(() => {

      new Swiper(imagesSlider, {

        modules: [Navigation],
        grabCursor: true,
        speed: 400,
        spaceBetween: 40,
        navigation: {
          prevEl: imagesSlider.querySelector('.button-prev'),
          nextEl: imagesSlider.querySelector('.button-next'),
        },

      });

    });

  },
}

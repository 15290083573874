/* eslint-disable */
import Swiper from 'swiper';
import Cart from '../modules/Cart';

// Declare variables
let container, formSlider, stepsIndicator, navigationContainer, requirementsSlide, useCartItems, productItems;
let qualified = true;
let heightUpdateSpeed = 100;
let isSubmitting = false;
const cartKey = 'sc-cart';

export default {

  init(el, useCart = true) {

    // Set element variables
    container = el.querySelector('.form-steps-container');
    stepsIndicator = el.querySelector('.steps-indicator');
    navigationContainer = container.querySelector('.navigation');
    requirementsSlide = container.querySelector('.requirements');

    useCartItems = useCart;

    window.isLoaded(() => {

      // Init slider
      formSlider = new Swiper(container, {

        autoHeight: true,
        allowTouchMove: false,
        on: {
          slideChange: () => {
            updateNavigation();
          },
          slideChangeTransitionEnd: () => {
            handleFormTabIndex();
          }
        },

      });

      // Fill products from cart
      if(useCartItems) {

        // Get product data from .products-data and map to an items array
        let cart = new Cart(cartKey);
        productItems = Array.from(container.querySelectorAll('.products-data .product-data')).map(dataElement => {
          return cart.createProductItem(cart.getProductData(dataElement));
        });

        // let productsField = container.querySelector('.final-form form input[name="products"]');
        let requiredSamplesField = container.querySelector('.final-form form select[name="required_samples[]"]');

        // Fill products strings
        // if(productsField) {

        //   productsField.value = productsValue;

        // }

        // Select required samples
        if(requiredSamplesField) {
          // let values = productsValue.split(',').map(element => element.trim());
          productItems.forEach(item => {
            let name = `${item.item_brand} ${item.item_name} ${item.item_variant}`;
            let option = requiredSamplesField.querySelector(`option[value="${name}"]`);
            if(option) {
              option.selected = true;
            }
          });
        }
      }

      // Listen to requirements answers to qualify checkout continuation
      if(requirementsSlide) {

        qualified = false;

        requirementsSlide.querySelectorAll('.question input').forEach(input => input.addEventListener('change', checkRequiredAnswers));

      } else {

        // Run navigation update
        updateNavigation();

      }

      // Listen to click on navigation buttons
      navigationContainer.querySelectorAll('button').forEach(navButton => navButton.addEventListener('click', navigateForm));

      // Listen to submit on forms (by hitting enter)
      container.querySelectorAll('.wpcf7 input').forEach(input => input.addEventListener('keypress', handleInputKeypress));

      // Toggle form tabindex
      handleFormTabIndex();

      // Listen to input validation to update height
      container.querySelectorAll('.wpcf7 input').forEach(input => {

        input.addEventListener('change', (e) => {

          setTimeout(() => {
            formSlider.updateAutoHeight(heightUpdateSpeed);
          }, 10);

        });

      });

   });

  },

}

/**
 * Callback for change on requirements questions.
 * Check all answers, there are 3 possibilities:
 * - all are true, set qualified to true and activate next button
 * - one is false, set qualified to false, deactivate next button and show not-valid message
 * - not all questions are answered yet, set qualified to false and deactivate next button
 */
const checkRequiredAnswers = () => {

  qualified = true;
  let showNotValid = false;

  // Go through every question
  requirementsSlide.querySelectorAll('.question').forEach(question => {

    let checkedRadioInput = question.querySelector('input:checked');

    // If the question is unanswered or the value is 0
    if(checkedRadioInput == null || checkedRadioInput.value == 0) {

      qualified = false;

    }

    // If the question is answered but the value is 0,  the not valid message must appear
    if(checkedRadioInput && checkedRadioInput.value == 0) {

      showNotValid = true;

    }

  });

  // Show or hide not-valid-message and update the slider height
  requirementsSlide.querySelector('.not-valid-message').classList.toggle('active', showNotValid);
  formSlider.updateAutoHeight(heightUpdateSpeed);

  // Update navigation
  updateNavigation();

};

/**
 * Update navigation elements
 * - Activate current slide in stepsIndicator
 * - Activate and deactivate previous and next buttons
 */
const updateNavigation = () => {

  // Update step-pagination
  stepsIndicator.querySelectorAll('.step').forEach((step, i) => {
    step.classList.toggle('active', i == formSlider.activeIndex);
  });

  // Update navigation buttons
  let prevButton = navigationContainer.querySelector('.prev');
  let nextButton = navigationContainer.querySelector('.next');

  prevButton.classList.toggle('active', formSlider.activeIndex != 0);
  nextButton.classList.toggle('active', qualified);

  // Change the next label according to the form being the final form or not
  nextButton.innerHTML = isFinalForm() ? nextButton.dataset.submitLabel : nextButton.dataset.nextLabel;

}

/**
 * Check if the key pressed on the input is enter, if so, prevent default submission and trigger click on next navigation button instead
 * @param  {Event} e
 */
const handleInputKeypress = (e) => {

  let key = e.charCode || e.keyCode || 0;
  if (key == 13) {

    e.preventDefault();
    navigationContainer.querySelector('.next').click();

  }
}

const navigateForm = (e) => {

  // Go back to previous slide
  if(e.target.classList.contains('prev')) {
    formSlider.slidePrev();
  }

  // Submit form and go to next slide if successful
  if(e.target.classList.contains('next') && !isSubmitting) {
    if(qualified) {

      submitSlideForm(() => {

        // Send order completion event if final form is successfully submitted
        if(isFinalForm()) {

          // Update process status as datalayer event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'add_shipping_info',
            step_number: formSlider.realIndex + 1,
            step_label: formSlider.clickedSlide.dataset.label,
            ecommerce: {
              items: productItems,
            },
          });

          // Sent purchase datalayer event
          window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              items: productItems,
            },
          });

          // Empty cart cookie
          if(useCartItems) {

            let cart = new Cart(cartKey);
            cart.empty();

          }

        } else {

          if(formSlider.realIndex == 0) {

            // Send begin checkout datalayer event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'begin_checkout',
              ecommerce: {
                items: productItems,
              },
            });

          } else {

            // Update process status as datalayer event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'add_shipping_info',
              step_number: formSlider.realIndex + 1,
              step_label: formSlider.clickedSlide.dataset.label,
              ecommerce: {
                items: productItems,
              },
            });
          }

          // Go to the next slide
          formSlider.slideNext();

        }

      });

    }
  }
};

const submitSlideForm = (callback) => {

  // Find the 'from' slide
  let slide = formSlider.slides[formSlider.realIndex];
  let formContainer = slide.querySelector('.wpcf7');

  // Continue if there is no form on this slide
  if(!formContainer) {
    callback();
    return;
  }

  // Start listening for the form to submit and continue if the validation passes
  formContainer.addEventListener('wpcf7submit', (e) => {

    setTimeout(() => {
      formSlider.updateAutoHeight(heightUpdateSpeed);
    }, 10);

    // When validation passes, collect the form data and insert it into the final form
    if(e.detail.apiResponse.status == 'mail_sent') {

      // Find the final form
      let finalForm = container.querySelector('.final-form form');

      // Reset all hidden checkbox fields of the final form
      finalForm.querySelectorAll('.hidden [type=checkbox]').forEach(checkbox => checkbox.checked = false);

      // Go through all fields of just submitted form
      e.detail.inputs.forEach(field => {

        // It is a checkbox value
        if(field.name.endsWith('[]')) {

          let hiddenField = finalForm.querySelector(`input[name="${field.name}"][value="${field.value}"]`);
          if(hiddenField) {

              hiddenField.checked = true;

          }

        // It is a regular value
        } else {

          let hiddenField = finalForm.querySelector(`input[name="${field.name}"]`);
          if(hiddenField) {

              hiddenField.value = field.value;

          }
        }

      });

      callback();

    }

    isSubmitting = false;

  }, { once: true });

  // Submit the form
  if(!isSubmitting) {
    isSubmitting = true;
    formContainer.querySelector('form [type=submit]').click();
  }

  formSlider.updateAutoHeight(heightUpdateSpeed);

  // Prevent form field reset
  formContainer.querySelector('form').addEventListener('reset', (e) => e.preventDefault(), { once: true });

};

const handleFormTabIndex = () => {

  let tabbables = 'input, textarea, select, [tabindex], a';

  container.querySelectorAll('.swiper-slide').forEach(slide => {

    let tabbable = slide.classList.contains('swiper-slide-active') ? '0' : '-1';

    slide.querySelectorAll(tabbables).forEach(el => {

      el.setAttribute('tabindex', tabbable);

    });
  });
};

/**
 * Check if the current slide contains the final form
 * @return {boolean}
 */
const isFinalForm = () => {

  return formSlider.slides[formSlider.realIndex].classList.contains('final-form');
}

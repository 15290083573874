export default {
  init() {

    const selectElement = document.querySelector('.products-form select');
    const values = JSON.parse(document.querySelector('.products-form [data-products-json]').dataset.productsJson);
    const formattedValues = Object.keys(values).map(group => {
      return {
        label: group,
        choices: values[group],
      };
    });

    // Set selected values
    Object.keys(values).forEach(group => {
      values[group].forEach(value => {
        if(value.selected) {
          const option = selectElement.querySelector(`option[value="${value.value}"]`);
          option.selected = true;
        }
      });
    });

    // Initialize Choices
    selectElement.Choices.clearChoices().setChoices(formattedValues);
    // new Choices(selectElement, {
    //   choices: formattedValues,
    //   removeItemButton: true,
    //   removeItemButtonAlignLeft: true,
    // });
  },
};

import Swiper, { Pagination } from 'swiper';

export default {

  init(container) {

    window.isLoaded(() => {

      new Swiper(container, {

        modules: [Pagination],
        slideClass: 'slide',
        slidesPerView: (6/4),
        spaceBetween: 16,
        speed: 400,
        pagination: {
          el: '.tabs',
          type: 'custom',
          bulletClass: 'tab',
          clickable: true,
          renderCustom: (swiper, current, total) => {

            let tabs = '';

            if(swiper.slides.length){
              for(let i = 1; i<=total; i++) {

                tabs += `<button role="tab" aria-selected="${current == i}" class="tab">${swiper.slides[i-1].dataset.label}</button>`

              }
            }

            return tabs;
          },
        },
        on: {
          slideChange: (swiper) => {

            let activeButton = swiper.pagination.el.querySelector('[aria-selected="true"]');
            swiper.pagination.el.scrollTo({
              top: 0,
              left: activeButton.offsetLeft - 26,
              behavior: 'smooth',
            });

          },
        },
        breakpoints: {
          1024: {
            slidesPerView: (8/6),
          },
        },

      });

    });

  },
}

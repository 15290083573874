export default {
  init() {

    // Window ready event
    window.isReady(() => {

      let newsletterForm = document.querySelector( '.newsletter-section .wpcf7' );

      if(newsletterForm) {

        newsletterForm.addEventListener( 'wpcf7submit', (event) => {


          if(event.detail.apiResponse.status == 'mail_sent') {

            newsletterForm.style.display = 'none';

            let messageElement = newsletterForm.closest('.form').querySelector('.message');

            messageElement.innerHTML = event.detail.apiResponse.message;
            messageElement.classList.add('show');

          }

        });

      }

    });

  },
}

import Breakpoints from '../util/Breakpoints';

let map;

export default {

  init(mapContainer) {

    window.isLoaded(initMaps.bind(null, mapContainer));
    window.addEventListener('resize', resizeMaps);

  },

}

/**
 * Create google maps instance
 * @param  {DOMElement} mapContainer
 */
const initMaps = (mapContainer) => {

  const google = window.google;

  const pinLocation = JSON.parse(mapContainer.dataset.pinLocation);
  const mapCenter = JSON.parse(mapContainer.dataset.mapCenter);
  const markerIcon = mapContainer.dataset.markerIcon;
  const mapStyles = JSON.parse(atob(mapContainer.dataset.mapStyles));

  const mapOptions = {
    center: mapCenter,
    disableDefaultUI: true,
    zoomControl: false,
    styles: mapStyles,
  }

  map = new google.maps.Map(mapContainer, mapOptions);

  setMapZoom();

  let markerImage = markerIcon ? { url: markerIcon } : '';
  new google.maps.Marker({
    position: pinLocation,
    map: map,
    icon: markerImage,
  });

}

/**
 * Set map properties on resize event
 */
const resizeMaps = () => {

  setMapZoom();

}

/**
 * Set the map zoom level according to breakpoint
 */
const setMapZoom = () => {

  new Breakpoints({
    default: () => { map.setZoom(12) },
    large: () => { map.setZoom(13) },
  });

}

import Swiper, {Navigation, EffectFade, Autoplay} from 'swiper';

export default {

  init(collectionSlideshow) {

    window.isLoaded(() => {

      new Swiper(collectionSlideshow, {

        modules: [Navigation, EffectFade, Autoplay],
        grabCursor: true,
        speed: 1000,
        effect: 'fade',
        autoplay: {
          delay: 3500,
        },
        navigation: {
          prevEl: collectionSlideshow.querySelector('.button-prev'),
          nextEl: collectionSlideshow.querySelector('.button-next'),
        },


      });

    });

  },
}

export default {
  init(selector, revealClass = 'revealed') {

    document.querySelectorAll(selector).forEach(element => {

      // Offset: The distance in percentages from the bottom of the screen where the intersection needs to trigger
      // Set data-offset attribute on the element for a custom offset
      const offset = element.dataset.offset ? element.dataset.offset : 10;

      // Threshold: Which part of the element should cross the offset-line to trigger the intersection. 0 means the top of the element, 1 means the bottom.
      // Set data-threshold attribute on the element for a custom threshold
      const threshold = element.dataset.threshold ? element.dataset.threshold : 0;

      if('IntersectionObserver' in window) {

        let observer = new IntersectionObserver(handleElementIntersect.bind(null, revealClass), { threshold: threshold, rootMargin: `0% 0% -${offset}% 0%` });
        observer.observe(element);

      } else {

        element.classList.add(revealClass);

      }

    });

  },
}

/**
 * Handle the element intersection. Add a visible class
 * @param  {array} entries
 * @param  {IntersectionObserver} observer
 */
const handleElementIntersect = (revealClass, entries) => {

  entries.forEach((entry) => {

    const element = entry.target;

    if(entry.isIntersecting) {

      if(element.dataset.delay !== undefined) {

        setTimeout(() => {
          element.classList.add(revealClass);
        }, parseInt(element.dataset.delay));

      } else {

        element.classList.add(revealClass);

      }

      // observer.disconnect();

    } else {

      element.classList.remove(revealClass);

    }
  });
}

import accordion from '../modules/accordion';

export default {
  init() {

    // Listen to search submission
    document.querySelector('.faq-search-form').addEventListener('submit', handleSearch);

    // Init faq accordion
    accordion.init(document.querySelector('.accordion'));

    // Listen to subject change
    document.querySelectorAll('[name=subject]').forEach(input => input.addEventListener('change', handleSubjectChange));

  },
}

const handleSearch = (e) => {

  // Prevent normal submit
  e.preventDefault();

  // Get form and values
  let form = e.target;
  let params = {
    'action': 'search_faq',
    's': form.querySelector('input[name="s"]').value,
  }

  form.classList.add('loading');

  // Push query to datalayer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'faq_search',
    'query': params.s,
  });

  // Fetch results
  fetch(`${window.main.ajax_url}?${new URLSearchParams(params).toString()}`)
  .then(response => response.json() )
  .then(data => {

    form.classList.remove('loading');

    if(data.ids) {

      // Disable all questions
      document.querySelectorAll('[data-question-id]').forEach(q => q.classList.remove('active'));

      // Unselect all topics
      document.querySelectorAll('.subjects input, .subjects option').forEach(input => {

        if(input.nodeName == 'OPTION') {

          input.selected = false;

        } else {

          input.checked = false;

        }

      });

      // Set search results title
      document.querySelector('[data-subject-title]').innerHTML = form.dataset.resultsTitle;

      // Activate all questions matching the search query
      data.ids.forEach(id => {

         document.querySelector(`[data-question-id="${id}"]`).classList.add('active');

      });

      document.querySelector('.no-results').classList.toggle('active', data.ids.length == 0);

    }


  });

}

/**
 * Handle a change on a subject input
 * @param  {event} e
 */
const handleSubjectChange = (e) => {

  let subject = e.target.value;
  let name;

  // Sync input elements with matching value
  document.querySelectorAll(`[value=${subject}]`).forEach(input => {

    name = input.dataset.name;

    if(input.nodeName == 'OPTION') {

      input.selected = true;

    } else {

      input.checked = true;

    }

  });

  // Toggle questions
  document.querySelectorAll('[data-subjects]').forEach(question => {

    let questionHasSubject = question.dataset.subjects.split(',').includes(subject);
    question.classList.toggle('active', questionHasSubject);

    // Set panel height for active questions
    if(questionHasSubject)
      accordion.setPanelHeight(question.querySelector('.panel'));

  });

  // Switch current title
  document.querySelector('[data-subject-title]').innerHTML = name;

  // Empty search field
  document.querySelector('.faq-search-form input[name="s"]').value = '';
}

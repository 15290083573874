const init = (tabs, tabContent) => {

  window.isLoaded(() => {

    // Set inital height for tab-content
    // setTabContentHeight(tabContent);

    // Add click event listener to each tab
    if(tabs){

      tabs.querySelectorAll('[role="tab"]').forEach(tab => {
        tab.addEventListener('click', toggleTab.bind(event, tabs, tabContent));
      });

    }

  });

  // Reset tab-content height on resize
  window.addEventListener('resize', () => {

    setTabContentHeight(tabContent);

  });
}

/**
 * Set the min-height of the tab-content element to the active element in the tab-content, or to the highest tab if fixedHeight is true
 * @param  {DOMElement} parent
 */
const setTabContentHeight = (tabContent) => {

  let minHeight = 0;

  if(tabContent.dataset.fixedHeight == '1') {

    tabContent.querySelectorAll(':scope > [role="tabpanel"]').forEach(panel => {
      if(panel.offsetHeight > minHeight) {
        minHeight = panel.offsetHeight;
      }
    });

  } else {

    minHeight = tabContent.querySelector(':scope > [role="tabpanel"].active').offsetHeight;

  }

  tabContent.style.minHeight = minHeight + 'px';


};

/**
 * Toggle the tab by removing active classes from tab and content and add them to the clicked tab
 * @param  {DOMElement} parent
 * @param  {Event} event
 */
const toggleTab = (tabs, tabContent, event) => {

  const currentTab = event.target;

  // Remove active from all tabs, and add to the clicked tab
  tabs.querySelectorAll('[role="tab"]').forEach(tab => tab.setAttribute('aria-selected', false));
  currentTab.setAttribute('aria-selected', true);

  // Remove active from all data-tab content elements and add to the clicked tab content
  tabContent.querySelectorAll(':scope > [role="tabpanel"]').forEach(panel => {

    panel.classList.remove('active');

  });

  tabContent.querySelector(`[data-panel="${currentTab.getAttribute('data-controls-panel')}"]`).classList.add('active', 'toggled');

  // Resize the tabcontent
  setTabContentHeight(tabContent);

}

export default {
  init: init,
  setTabContentHeight: setTabContentHeight,
};

/* eslint-disable */

let foldersListContainer;

export default {
  init() {

    foldersListContainer = document.querySelector('.folders-list');
    foldersListContainer.querySelectorAll('[data-filter]').forEach(filter => filter.addEventListener('click', () => {

      filterDownloads(filter);
      toggleFolders(filter);

    }));

    document.querySelector('.open-folders-menu').addEventListener('click', openFoldersMenu);
    document.querySelectorAll('.close-folders-menu').forEach(button => button.addEventListener('click', closeFoldersMenu));


    // Listen to search submission
    document.querySelector('.downloads-search-form').addEventListener('submit', handleSearch);

  },
}

const filterDownloads = (selectedFilter) => {

  // currentFilter = filter;

  document.querySelectorAll('.downloads .download').forEach(download => {

    // Hide download
    let showDownload = false;

    // Loop through the filter and all of it's parent filters. Set showDownload according to if the filter value is present on the download
    let loopFilter = selectedFilter;

    while(loopFilter) {

      let filterType = loopFilter.dataset.filterType;
      let filterValue = loopFilter.dataset.filterValue;

      console.log(filterType + ': ' + filterValue);

      // Set showDownload to true if the download has the filter value in it's dataset, otherwise false
      showDownload = filterType in download.dataset && download.dataset[filterType].split(',').includes(filterValue);

      // Stop looping immediately if the showDownload is false
      if(!showDownload) {
        break;
      }

      // Check if there is a parent data-filter
      if(loopFilter.closest('ul').closest('.data-filter-container')) {

        // Set loopFilter to next parent data-filter
        loopFilter = loopFilter.closest('ul').closest('.data-filter-container').querySelector(':scope > [data-filter]');

      } else {

        break;

      }
    }

    // Toggle download according to the showDownload boolean
    download.classList.toggle('hide', !showDownload);

    // Set title above folders
    document.querySelector('.folder-title').innerHTML = selectedFilter.innerHTML;

  });

}

const toggleFolders = (filter) => {

  // Remove all current classes and add current class to filter
  foldersListContainer.querySelectorAll('[data-filter]').forEach(f => f.classList.remove('current'));
  filter.classList.add('current');

  // let allFolders = foldersListContainer.querySelectorAll('ul');
  let directSubFolders = filter.closest('li').querySelectorAll(':scope > ul');

  // Close all subfolders of sibling folders
  let parentList = filter.closest('ul');

  if(parentList) {

    // Close all folders if parentList is the top level container
    if(parentList === foldersListContainer) {

      // Add hide class to all the ul's
      parentList.querySelectorAll('ul').forEach(folder => folder.classList.add('hide'));

    }

    // Close all sibling folders if parentList is not the top level container
    else {

      // Find the parent li and its direct ul childs to select all siblings
      parentList.closest('li').querySelectorAll(':scope > ul').forEach(siblingFolder => {

        // Find each ul in the siblings and add hide class
        siblingFolder.querySelectorAll('ul').forEach(folder => folder.classList.add('hide'));

      });

    }
  }

  // Show direct subfolders and hide deeper subfolders
  directSubFolders.forEach(subFolder => {
    subFolder.classList.remove('hide');
    // console.log(subFolder);
    subFolder.querySelectorAll('ul').forEach(f => f.classList.add('hide'));
  });

}

const openFoldersMenu = () => {

  document.querySelector('body').classList.add('no-scroll');
  document.querySelector('.folders-wrapper').classList.add('open');

}

const closeFoldersMenu = () => {

  document.querySelector('body').classList.remove('no-scroll');
  document.querySelector('.folders-wrapper').classList.remove('open');

}

const handleSearch = (e) => {

  // Prevent normal submit
  e.preventDefault();

  // Get form and values
  let form = e.target;
  let searchQuery = form.querySelector('input[name="s"]').value;
  let params = {
    'action': 'search_downloads',
    's': searchQuery,
  }

  form.classList.add('loading');

  // Push query to datalayer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'downloads_search',
    'query': params.s,
  });

  // Fetch results
  fetch(`${window.main.ajax_url}?${new URLSearchParams(params).toString()}`)
  .then(response => response.json() )
  .then(data => {

    form.classList.remove('loading');

    if(data.ids) {

      // Disable all questions
      document.querySelectorAll('[data-download-id]').forEach(q => q.classList.add('hide'));

      // Close all folders


      // Set search results title
      document.querySelector('.folder-title').innerHTML = form.dataset.searchPrefix + ' &quot;' + searchQuery + '&quot;';

      // // Activate all questions matching the search query
      data.ids.forEach(id => {

        // Find download element
        let downloadElement = document.querySelector(`[data-download-id="${id}"]`)

        if(downloadElement) {

          downloadElement.classList.remove('hide');

        }

      });

      // document.querySelector('.no-results').classList.toggle('active', data.ids.length == 0);

    }


  });

}

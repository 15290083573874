import Cart from '../modules/Cart';
import animations from '../util/animations';

export default {
  init() {

    // Window ready event
    window.isReady(() => {

      // Listen for click on order button
      document.querySelectorAll('.remove-from-cart').forEach(button => button.addEventListener('click', removeFromBasket.bind(null, button)));

    });

  },
}

/**
 * Remove product from the cart
 * @param  {DOMElement} button
 */
const removeFromBasket = (button) => {

  let cart = new Cart('sc-cart');
  let product = button.closest('.product');
  let dataElement = product.querySelector('.product-data');
  // let product_name = `${button.dataset.productSlug}-${button.dataset.textureSlug}`;

  cart.remove(dataElement);

  // Remove product from page
  animations.slideUp(product, 300, () => {

    product.remove();

  });

  // Handle empty message and checkout button
  if(cart.getAmount() == 0) {
    document.querySelector('.empty-message').classList.add('show');
    document.querySelector('.checkout-button').classList.add('hide');
  }

  // Handle too many products in cart
  document.querySelector('.checkout-button').classList.toggle('disabled', cart.getAmount() > cart.maxProducts);

}

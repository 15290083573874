import filters from '../modules/filters';

export default {
  init() {

    filters.init(
      document.querySelector('.results-container'),
      document.querySelector('.filters'),
      document.querySelector('.active-filters')
    );

  },
}

import Swiper, { Navigation, FreeMode } from 'swiper';

export default {

  init(sliderContainer) {

    window.isLoaded(() => {

      new Swiper(sliderContainer, {

        modules: [Navigation, FreeMode],
        slidesPerView: 'auto',
        spaceBetween: 40,
        grabCursor: true,
        speed: 400,
        freeMode: true,
        navigation: {
          prevEl: `.navigation[data-navigation-index="${sliderContainer.dataset.sliderIndex}"] .button-prev`,
          nextEl: `.navigation[data-navigation-index="${sliderContainer.dataset.sliderIndex}"] .button-next`,
        },
        breakpoints: {
          1024: {
            spaceBetween: 60,
          },
          1440: {
            spaceBetween: 90,
          },
        },

      });

    });

  },
}
